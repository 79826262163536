import React, { useMemo, useState } from 'react';
import {
  getIconCell,
  Icon,
  QueryCell,
  QueryTable,
  SelectOption,
  Transaction,
  TransferSummaryRest,
  useFlags,
  useTranslation,
} from 'common';
import DateTimeCell from '../../components/DateTimeCell';
import { TransferStatus } from '../../services/transfer';
import StatusLabel from '../../components/StatusLabel';
import TransferOptionsButton from 'app/src/components/Transfer/TransferOptionsButton';
import PaymentForm from 'app/src/components/Transfer/PaymentForm';
import Page from '../../components/Page';
import { getTransactionDetailLink } from '../../services/api/transactions/transactionUtils';
import { apiClient } from '../../services/httpClients/app';
import { usePaymentMethods } from '../../services/paymentMethods';
import TransactionDialog from '../Transactions/TransactionDialog';
import IDField from '../../components/IDField';
import styles from './PaymentsPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { useIssueRefundDrawer } from '../Refunds/hooks/useIssueRefundDrawer';
import { IssueRefundDrawer } from '../Refunds/components/IssueRefundDrawer';

export const PaymentsPage = () => {
  const { refunds: refundsFlag } = useFlags();
  const { tk } = useTranslation();
  const navigate = useNavigate();
  const [paymentDialogState, setPaymentDialogState] = useState(false);
  const [transactionId, setTransactionId] = useState<string | undefined>();
  const [detailLink, setDetailLink] = useState<string | undefined>();
  const [paymentEditing, setPaymentEditing] = useState<
    Transaction | undefined
  >();

  const [selectedTransferCompanyName, setSelectedTransferCompanyName] =
    useState<string | undefined>(undefined);

  const {
    isIssueRefundDrawerOpen,
    selectedTransferId,
    openIssueRefundDrawer,
    closeIssueRefundDrawer,
  } = useIssueRefundDrawer();

  const handleTableRowClick = (selectedRow: Transaction) => {
    navigate(`/payments/${selectedRow.id}`);
  };

  const { data: paymentMethods } = usePaymentMethods();
  const paymentTypeOptions: SelectOption<string>[] = useMemo(() => {
    if (!paymentMethods) return [];
    const seenMethods = new Set<string>();
    const paymentMethodOptions: SelectOption<string>[] = [];
    for (const method of paymentMethods) {
      if (method.paymentType && !seenMethods.has(method.paymentType)) {
        seenMethods.add(method.paymentType);
        paymentMethodOptions.push({
          value: String(method.paymentType),
          name: String(method.name),
        });
      }
    }
    return paymentMethodOptions;
  }, [paymentMethods]);

  const statusOptions: SelectOption<string>[] = Object.keys(TransferStatus).map(
    (key) => ({
      value: key,
      name: TransferStatus[key as keyof typeof TransferStatus].valueOf(),
    })
  );

  const cells: QueryCell<TransferSummaryRest>[] = [
    getIconCell(Icon.CurrencyUsd),
    {
      key: 'id',
      headerLabel: tk('Payment Id'),
      align: 'left',
      width: '120px',
      sortKey: 'id',
      sortable: true,
      renderCell: (data: TransferSummaryRest) => (
        <IDField documentID={data.id}>
          <div>{data.id?.substring(0, 8) ?? '-'}</div>
        </IDField>
      ),
    },
    {
      key: 'companyName',
      headerLabel: tk('Customer'),
      width: '20%',
      overflowCell: true,
      sortable: true,
      sortKey: 'invoice.customer.name',
      renderCell: (data: TransferSummaryRest) => (
        <div>
          <div className={styles.truncate}>{data.companyName ?? '-'}</div>
        </div>
      ),
    },
    {
      key: 'paymentMethod',
      headerLabel: tk('Payment Method'),
      width: '120px',
      sortable: true,
      sortKey: 'paymentMethod.name',
      renderCell: (data: TransferSummaryRest) =>
        data.paymentMethod?.name ?? '-',
    },
    {
      key: 'amount',
      headerLabel: tk('Amount'),
      width: '120px',
      sortable: true,
      renderCell: (transaction: TransferSummaryRest) =>
        transaction.amountFormatted,
    },
    {
      key: 'processedAt',
      headerLabel: tk('Payment Date'),
      width: '120px',
      sortable: true,
      renderCell: (transaction: TransferSummaryRest) => (
        <DateTimeCell date={transaction.processedAt} showTime={false} />
      ),
    },
    {
      key: 'status',
      headerLabel: tk('Status'),
      width: '120px',
      sortable: true,
      renderCell: (data: TransferSummaryRest) => {
        return (
          <button
            className={styles.textCenter}
            onClick={() => {
              setDetailLink(getTransactionDetailLink(data) ?? 'na');
              setTransactionId(data.id);
              setPaymentDialogState(true);
            }}
            type="button"
          >
            <StatusLabel feature="transaction" status={data.status ?? ''} />
          </button>
        );
      },
    },
    {
      key: 'options',
      headerLabel: tk('Options'),
      align: 'center',
      width: 64,
      clickable: true,
      renderCell: (data: TransferSummaryRest) => (
        <div className={styles.transferOptionsButton}>
          <TransferOptionsButton
            borderless
            onClickEvent={() => {
              setPaymentEditing(data);
            }}
            onClickInitiateRefund={() => {
              setSelectedTransferCompanyName(data.companyName);
              openIssueRefundDrawer(data.id ?? '');
            }}
            onClickViewActivity={() => {
              setTransactionId(data.id);
              setPaymentDialogState(true);
            }}
            referenceId={data.referenceId}
            referenceType={data.referenceType ?? ''}
            transfer={data}
          />
        </div>
      ),
    },
  ];

  const renderRefundDrawer =
    refundsFlag &&
    isIssueRefundDrawerOpen &&
    selectedTransferId &&
    selectedTransferCompanyName;

  return (
    <Page leftWidget="Payments">
      <PaymentForm
        detailLink={detailLink ?? 'na'}
        isOpen={!!paymentEditing}
        onCloseForm={() => {
          setPaymentEditing(undefined);
        }}
        transfer={paymentEditing}
      />
      <QueryTable<TransferSummaryRest>
        cells={cells}
        fetchList={apiClient.listTransactions}
        filters={[
          {
            type: 'search',
            key: 'search',
            searchFields: ['invoice.customer.name'],
          },
          {
            type: 'dropdown',
            key: 'status',
            humanReadableName: 'Status',
            fieldName: 'status',
            options: statusOptions,
          },
          {
            type: 'valueRadio',
            key: 'amount',
            humanReadableName: 'Amount',
            fieldName: 'amount',
            prefix: '$',
          },
          {
            type: 'dropdown',
            key: 'paymentMethod.paymentType',
            humanReadableName: 'Payment method',
            fieldName: 'paymentMethod.paymentType',
            options: paymentTypeOptions,
          },
          {
            type: 'dateRadio',
            key: 'processedAt',
            humanReadableName: 'Payment date',
            fieldName: 'processedAt',
            optionsType: 'currentAndPast',
          },
        ]}
        onRowClick={handleTableRowClick}
        queryKey="apiClient.listTransactions"
      />
      {paymentDialogState && (
        <TransactionDialog
          detailLink={detailLink ?? 'na'}
          isOpen={paymentDialogState}
          setOpenState={setPaymentDialogState}
          transactionId={transactionId}
        />
      )}

      {renderRefundDrawer && (
        <IssueRefundDrawer
          customerName={selectedTransferCompanyName}
          isOpen={isIssueRefundDrawerOpen}
          onClose={closeIssueRefundDrawer}
          transferId={selectedTransferId}
        />
      )}
    </Page>
  );
};
