import React from 'react';

import { Icon, Refund } from 'common';

import ActionBar from 'app/src/components/ActionBar';
import StatusLabel from 'app/src/components/StatusLabel';

import styles from './RefundActionsHeader.module.scss';
import ActionBarButton from 'app/src/components/ActionBarButton';

interface Props {
  refund: Refund;
  openActivityDrawer: () => void;
}

export const RefundActionsHeader: React.FC<Props> = ({
  refund,
  openActivityDrawer,
}) => {
  return (
    <div className={styles.headerContainer}>
      <StatusLabel feature="refund" status={refund?.status ?? ''} />
      <ActionBar>
        <ActionBarButton
          icon={Icon.Activity}
          onClick={openActivityDrawer}
          tooltip="Activity"
        />
      </ActionBar>
    </div>
  );
};
